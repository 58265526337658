/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-unused-expressions */
/* Call API with token */

import Storage from "./storega";

import { pushNotify } from "../components/notifycation";
import { messageError } from "../constant/messageError";

const convertToUrl = params => {
  const url = new URLSearchParams();
  Object.keys(params).forEach(key => {
    if (params[key]) {
      url.append(key, params[key]);
    }
  });
  return url.toString();
};

const SYSTEM_ERROR = 1;
const API_ERROR = 2;
export const TOKEN_TIMEOUT = 3;
export const SYSTEM_IS_MAINTAINING = 4;
class ApiError extends Error {
  constructor(error, type) {
    super();
    this.errors = error;
    this.type = type;
  }
}
const broadcast = new BroadcastChannel('channel_error');
async function fakeResponse(){
  const STATUS = 400;
  const response = {
    status: STATUS,
    ok: false,
    json: () => ({
        status: STATUS,
        message: 'EXAM_HAS_BEEN_SCORED'
      })
  }
  let error = {};
  const err = await response.json();
  error = {
    messageId: err?.status,
    message: err?.message
  };
  throw new ApiError(error);
}
async function validateResponse(response) {
  // const STATUS = 502;
  // const response = {
  //   status: STATUS,
  //   ok: false,
  //   json: () => ({
  //       status: STATUS,
  //       message: 'DEBUG_SHOW_ERROR'
  //     })
  // }
  if (!response.ok) {
    let error = {};
    let type = API_ERROR;
    const err = await response.json();
    switch (response.status) {
      case 400:
        // eslint-disable-next-line no-case-declarations 
        type = TOKEN_TIMEOUT;
        error = {
          messageId: err.status,
          message: err.message
        };
        error.message === "UNAUTHORIZED" && broadcast.postMessage('Unauthorized-400');
        break;
      case 503:
        type = SYSTEM_IS_MAINTAINING;
        error = {
          messageId: err.status,
          message: 'SYSTEM_IS_MAINTAINING'
          // message: err.message
        };
      // use broadcast to send message 503 to all tabs
        broadcast.postMessage('Maintain-503');
        break;
      default:
        type = SYSTEM_ERROR;
        error = {
          messageId: err.status,
          message: err.message
          // message: response.statusText,
        };
        break;
    }

    throw new ApiError(error);
  }
  return response.json();
}

function logError(error) {
  if (error instanceof ApiError) {
    // eslint-disable-next-line no-console
    console.log("mess error", error.errors)
    // Check first messageId is 5xx 
    const isErrorServer = error.errors.messageId && error.errors.messageId.toString().startsWith('5') && !error.errors.messageId.toString().endsWith('3')
    const caseHideCodeSever = ['SYSTEM_IS_MAINTAINING', 'DEBUG_SHOW_ERROR', 'ERROR_REQUEST_TIMEOUT']
    const chooseMessError = messageError[error.errors.message] !== undefined ?  messageError[error.errors.message] : messageError.default
    const condShowMess = Boolean(isErrorServer && !caseHideCodeSever.includes(error.errors.message))
    const showMess = condShowMess ? `${error.errors.messageId} - ${chooseMessError}` : chooseMessError;
    pushNotify('error', showMess) ;
  }
  // throw error;
  return false;
}

const callApi = (
  url,
  config,
  onRequestFinish,
  onRequestSuccess,
  onRequestFailure,
  onFinally = () => {},
  otherProps = {
    isFakeRes: false,
    pushLog: true,
    isHaveLogParams: false,
    isFirstParams: false
  }
) => {
  const { isFakeRes = false , pushLog = true, isHaveLogParams = false,  isFirstParams = false } = otherProps 
  const getCurrrenStudent = Storage.get('INFO_USER_LOGIN')
  fetch(isHaveLogParams ? `${url}${isFirstParams ? '?' : '&'}${convertToUrl({
    systemCode: getCurrrenStudent?.systemCode,
    userId: getCurrrenStudent?.username
  })}` : url, config)
    .then(isFakeRes ? fakeResponse : validateResponse)
    .then((data) => {
      if (data) {
        onRequestFinish && onRequestFinish();
        onRequestSuccess && onRequestSuccess(data);
      }
    })
    .catch((err) => {
      pushLog && logError(err);
      onRequestFinish && onRequestFinish();
      onRequestFailure && onRequestFailure(err);
    }).finally(onFinally);
};


let prResolve = {}

const newCallApi = (
  url,
  config,
  onRequestFinish,
  onRequestSuccess,
  onRequestFailure,
  otherProps = {
    isHaveLogParams: false,
    isFirstParams: false
  }
) => {
  const { isHaveLogParams = false, isFirstParams = false } = otherProps
  const getCurrrenStudent = Storage.get('INFO_USER_LOGIN')
  const promise = prResolve[url] ?
  Promise.resolve(prResolve[url]) :
  fetch(
    isHaveLogParams ? `${url}${isFirstParams ? '?' : '&'}${convertToUrl({
      systemCode: getCurrrenStudent?.systemCode,
      userId: getCurrrenStudent?.username
    })}` : url
    , config)
    .then(validateResponse)
    .then((data) => {
      if (data) {
       // onRequestSuccess(data);
        onRequestFinish && onRequestFinish();
        onRequestSuccess && onRequestSuccess(data);
      }
    })
    .catch((err) => {
      logError(err);
      onRequestFinish && onRequestFinish();
      onRequestFailure && onRequestFailure(err);
    });

  prResolve[url] = promise

  return promise;

};

const clearPrResolve = () => {
  prResolve = {};
  // return prResolve; 
}

const callApiNoToken = (
  url,
  config,
  onRequestFinish,
  onRequestSuccess,
  onRequestFailure
) => {
  fetch(url, config)
    .then(validateResponse)
    .then((data) => {
      if (data) {
        data && onRequestSuccess && onRequestSuccess(data);
      }
    })
    .catch((err) => {
      logError(err);
      onRequestFinish && onRequestFinish();
      onRequestFailure && onRequestFailure(err);
    });
};

const logoutAdmin = () => {
  if(Storage.get("ADMIN_LOGIN")){
    Storage.remove("ADMIN_LOGIN")
    Storage.remove("SCHOOL_ID");
    Storage.remove('SYSTEM_CODE');
    Storage.remove('OLD_SYSTEM_CODE');
    Storage.remove("MAIN_MODE");
    Storage.remove('OLD_MAIN_MODE');
  }
}

export { callApi, callApiNoToken, newCallApi, clearPrResolve, logoutAdmin };