/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
import LocalStorage from "../../utils/storega";
import { TYPES } from "../actions";

const initialState = {
  status: null,
  error: null,
  currentStep: {},
  nextStep: {},
  previousStep: {},
  isLoading: false,
  lessonCourse: [],
  urlFile: null,
  fileName: "",
  resultLesson: "",
  nextLesson: null,
  examDetailLesson: {},
  commentAI: "",
  smallQuestionData: [],
  oneHundredScore: null,
  examInfo: null,
  isExistNextLesson: true,
  nextStepScoring: null,
  currentExamByUser: {},
  listExams: [],
  questionByExamId: {},
  hasPrintExamManyTimeSetting: false,
  roadmapData: {},
  resultScore: {},
  nextExamId: null,
  dataGenerationExam: {},
  listLessonCurrentOfUserWithToken: null, // => Null to check exist list, true return { data - pagination }
  completeGetCurrent: false,
  currentExam: {},
  listMoshiExamResultImport: [],
  generateExamData: {},
  createFirstExamData: {},
  smallExamData: {},
  moshiScore: {},
  updateStudyModeData: {},
  updateStatusExam: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    // ACTIONS WITHOUT DATA WILL BE PLACES HERE
    case TYPES.GET_RAOD_MAP_REQUEST:
      return {
        ...state,
        status: action.type,
        isLoading: true,
        roadmapData: {}
      };
    case TYPES.SCORING_SMALL_QUESTION_REQUEST:
      return {
        ...state,
        status: action.type,
        isLoading: true,
        resultScore: {},
        nextExamId: null
      };
    case TYPES.GET_LESSON_COURSE_REQUEST:
    case TYPES.GET_CURRENT_LESSON_REQUEST:
    case TYPES.CHECK_CREATE_FIRST_EXAM_REQUEST:
    case TYPES.CHECK_RESULT_LESSON_REQUEST:
    case TYPES.GET_EXAM_DETAIL_REQUEST:
    case TYPES.CHECK_CREATE_EXAM_REQUEST:
    case TYPES.GET_COMMENT_AI_REQUEST:
    case TYPES.GET_SMALL_QUESTION_REQUEST:
    case TYPES.GET_CURRENT_EXAM_BY_USER_REQUEST:
    case TYPES.GET_ROAD_MAP_ALL_REQUEST:
    case TYPES.GET_LIST_QUESTION_BY_EXAMID_REQUEST:
      return {
        ...state,
        status: action.type,
        isLoading: true
      };
    case TYPES.GENERATION_EXAM_REQUEST:
      return {
        ...state,
        status: action.type,
        isLoading: true,
        urlFile: null,
        fileName: "",
        dataGenerationExam: {}
      };
    // ACTIONS WITH DATA WILL BE PLACES HERE
    case TYPES.GET_RAOD_MAP_SUCCESS:
      return {
        ...state,
        status: action.type,
        dataRoadMap: action.data.roadMap.columns,
        currentStep: action.data.roadMap.currentStep,
        nextStep: action.data.roadMap.nextStep,
        previousStep: action.data.roadMap.previousStep,
        isLoading: false,
        hasPrintExamManyTimeSetting:
          action.data.roadMap.hasPrintExamManyTimeSetting,
        roadmapData: action.data.roadMap
      };

    case TYPES.GET_LESSON_COURSE_SUCCESS:
      return {
        ...state,
        status: action.type,
        lessonCourse: action.data.lessonCourse,
        isLoading: false
      };

    case TYPES.GENERATION_EXAM_SUCCESS:
      return {
        ...state,
        // status: action.type,
        urlFile: action.data.exam.fileUrl,
        fileName: action.data.exam.fileName,
        dataGenerationExam: action.data.exam
        // isLoading: true
      };
    case TYPES.GET_CURRENT_LESSON_SUCCESS:
      return {
        ...state,
        status: action.type,
        currentLesson: action.data.currentLesson,
        isLoading: false
      };

    case TYPES.CHECK_CREATE_EXAM_SUCCESS:
      return {
        ...state,
        status: action.type,
        isLoading: false
      };

    case TYPES.CHECK_CREATE_FIRST_EXAM_SUCCESS:
      return {
        ...state,
        status: action.type,
        isLoading: false
      };

    case TYPES.CHECK_RESULT_LESSON_SUCCESS:
      // action.data.resultLesson?.isExistNextLesson
      // ? LocalStorage.set(
      //   "CURRENT_EXAM_BY_USER",
      //   action.data.resultLesson?.nextLesson
      // )
      // : "";
      return {
        ...state,
        status: action.type,
        resultLesson: action.data.resultLesson?.result,
        nextLesson: action.data.resultLesson?.nextLesson,
        isExistNextLesson: action.data.resultLesson?.isExistNextLesson,
        isLoading: false
      };

    case TYPES.GET_EXAM_DETAIL_SUCCESS:
      return {
        ...state,
        status: action.type,
        isLoading: false,
        examDetailLesson: action.data.examDetail
      };

    case TYPES.GET_COMMENT_AI_SUCCESS:
      return {
        ...state,
        status: action.type,
        isLoading: false,
        commentAI: action.data.comment
      };

    case TYPES.GET_SMALL_QUESTION_SUCCESS:
      return {
        ...state,
        status: action.type,
        isLoading: false,
        smallQuestionData: action.data.smallQuestion.questionInfo,
        oneHundredScore: action.data.smallQuestion.oneHundredScore
      };

    case TYPES.SCORING_SMALL_QUESTION_SUCCESS:
      return {
        ...state,
        status: action.type,
        isLoading: false,
        examInfo: action.data.score.examInfo,
        nextStepScoring: action.data.score.nextStep || null,
        resultScore: action.data.score,
        nextExamId: action.data.score.nextExamId || null
      };

    case TYPES.CLEAR_DATA_SUCCESS:
      return {
        ...initialState
      };

    case TYPES.GET_CURRENT_EXAM_BY_USER_SUCCESS:
      // eslint-disable-next-line no-unused-expressions
      action.data.currentExamByUser
        ? LocalStorage.set(
          "CURRENT_EXAM_BY_USER",
          action.data.currentExamByUser
        )
        : "";
      return {
        ...state,
        status: action.type,
        currentExamByUser: action.data.currentExamByUser
      };

    case TYPES.GET_ROAD_MAP_ALL_SUCCESS:
      return {
        ...state,
        status: action.type,
        listExams: action.data.listExams,
        isLoading: false //* 6-1-22
      };

    case TYPES.GET_LIST_QUESTION_BY_EXAMID_SUCCESS:
      return {
        ...state,
        status: action.type,
        questionByExamId: action.data?.questionByExamId,
        isLoading: false
      };

    case TYPES.SET_LOADING:
      return {
        ...state,
        status: action.type,
        isLoading: action.data.isLoading
      };
    case TYPES.CLEAR_MOSHI_SCORE_SUCCESS:
      return {
        ...state,
        moshiScore: {}
      };

    // ACTION FAILED WILL BE PLACED HERE
    case TYPES.GET_RAOD_MAP_FAILED:
    case TYPES.GET_LESSON_COURSE_FAILED:
    case TYPES.GET_CURRENT_LESSON_FAILED:
    case TYPES.CHECK_CREATE_EXAM_FAILED:
    case TYPES.CHECK_CREATE_FIRST_EXAM_FAILED:
    case TYPES.CHECK_RESULT_LESSON_FAILED:
    case TYPES.GET_EXAM_DETAIL_FAILED:
    case TYPES.GENERATION_EXAM_FAILED:
    case TYPES.GET_COMMENT_AI_FAILED:
    case TYPES.GET_SMALL_QUESTION_FAILED:
    case TYPES.SCORING_SMALL_QUESTION_FAILED:
    case TYPES.GET_CURRENT_EXAM_BY_USER_FAILED:
    case TYPES.GET_ROAD_MAP_ALL_FAILED:
    case TYPES.GET_LIST_QUESTION_BY_EXAMID_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        isLoading: false
      };
    case TYPES.GET_LIST_CURRENT_LESSON_USER_REQUEST:
      LocalStorage.set("MODE_SELECTED", "LIST")
      return {
        ...state,
        listLessonCurrentOfUserWithToken: null,
        completeGetCurrent: false
      };
    case TYPES.GET_LIST_CURRENT_LESSON_USER_SUCCESS:
      return {
        ...state,
        listLessonCurrentOfUserWithToken: action.data.list || {},
        completeGetCurrent: true
      };
    case TYPES.GET_LIST_CURRENT_LESSON_USER_FAILED:
      return {
        ...state,
        listLessonCurrentOfUserWithToken: {},
        completeGetCurrent: true
      };
    case TYPES.GET_CURRENT_EXAM_REQUEST:
      return {
        ...state,
        currentExam: {}
      };
    case TYPES.GET_CURRENT_EXAM_SUCCESS:
      return {
        ...state,
        currentExam: action.data || {}
      };
    case TYPES.GET_CURRENT_EXAM_FAILED:
      return {
        ...state,
        currentExam: {}
      };
    case TYPES.GET_LIST_MOSHI_EXAM_RESULT_IMPORT_REQUEST:
      return {
        ...state,
        listMoshiExamResultImport: []
      };
    case TYPES.GET_LIST_MOSHI_EXAM_RESULT_IMPORT_SUCCESS:
      return {
        ...state,
        listMoshiExamResultImport: action.data.list || []
      };
    case TYPES.GET_LIST_MOSHI_EXAM_RESULT_IMPORT_FAILED:
      return {
        ...state,
        listMoshiExamResultImport: []
      };
    case TYPES.GENERATE_EXAM_REQUEST:
      return {
        ...state,
        generateExamData: {}
      };
    case TYPES.GENERATE_EXAM_SUCCESS:
      return {
        ...state,
        generateExamData: action.data.data || {}
      };
    case TYPES.GENERATE_EXAM_FAILED:
      return {
        ...state,
        generateExamData: {}
      };
    case TYPES.CREATE_FIRST_EXAM_REQUEST:
      return {
        ...state,
        createFirstExamData: {}
      };
    case TYPES.CREATE_FIRST_EXAM_SUCCESS:
      return {
        ...state,
        createFirstExamData: action.data.data || {}
      };
    case TYPES.CREATE_FIRST_EXAM_FAILED:
      return {
        ...state,
        createFirstExamData: {}
      };
    case TYPES.GET_MOSHI_SMALL_EXAM_REQUEST:
      return {
        ...state,
        smallExamData: {}
      };
    case TYPES.GET_MOSHI_SMALL_EXAM_SUCCESS:
      return {
        ...state,
        smallExamData: action.data.data || {}
      };
    case TYPES.GET_MOSHI_SMALL_EXAM_FAILED:
      return {
        ...state,
        smallExamData: {}
      };
    case TYPES.MOSHI_SCORING_REQUEST:
      return {
        ...state,
        moshiScore: {}
      };
    case TYPES.MOSHI_SCORING_SUCCESS:
      return {
        ...state,
        moshiScore: action.data.data || {}
      };
    case TYPES.MOSHI_SCORING_FAILED:
      return {
        ...state,
        moshiScore: {}
      };
    case TYPES.MOSHI_RESET_REQUEST:
      return {
        ...state,
        moshiReset: {}
      };
    case TYPES.MOSHI_RESET_SUCCESS:
      return {
        ...state,
        moshiReset: action.data.data || {}
      };
    case TYPES.MOSHI_RESET_FAILED:
      return {
        ...state,
        moshiReset: false
      };
    case TYPES.UPDATE_STUDY_MODE_REQUEST:
      return {
        ...state,
        updateStudyModeData: {}
      };
    case TYPES.UPDATE_STUDY_MODE_SUCCESS:
      return {
        ...state,
        updateStudyModeData: action.data.data || {}
      };
    case TYPES.UPDATE_STUDY_MODE_FAILED:
      return {
        ...state,
        updateStudyModeData: {}
      };
    case TYPES.UPDATE_STATUS_EXAM_REQUEST:
      return {
        ...state,
        updateStatusExam: false
      };
    case TYPES.UPDATE_STATUS_EXAM_SUCCESS:
      return {
        ...state,
        updateStatusExam: action.data.data || {}
      };
    case TYPES.UPDATE_STATUS_EXAM_FAILED:
      return {
        ...state,
        updateStatusExam: false
      };
    default:
      return state;
  }
};
