export default [
  'SIGN_IN',
  "LOGOUT",
  'GET_RAOD_MAP',
  'GET_RAOD_MAP_MEDIUM',
  'GET_RAOD_MAP_HIGH',
  'GET_ROAD_MAP_PREPARATION',
  'GET_ROAD_MAP_ACHIEVEMENT',
  'GET_ROAD_MAP_PRACTICE',
  'GET_LESSON_COURSE',
  'GENERATION_EXAM',
  'GET_EXAM_DETAIL',
  'GET_CURRENT_LESSON',
  'GET_CURRENT_EXAM_BY_USER',
  'CHECK_CREATE_EXAM',
  'CHECK_CREATE_FIRST_EXAM',
  'GET_SCHEDULE',
  'GET_SCHEDULE_DETAILS',
  'RESTART_SCHEDULE_DETAILS',
  'GET_SUBJECT_LIST',
  'CHECK_RESULT_LESSON',
  'GET_EXAM_DETAIL',
  'CLEAR_DATA',
  'CLEAR_DATA_AUTH',
  'GET_COMMENT_AI',
  'CLEAR_DATA_SCHEDULE_DETAILS',
  'GET_SMALL_QUESTION',
  'SCORING_SMALL_QUESTION',
  'GET_PROFILE',
  'GET_SCHOOL_LEVEL_BY_SUBJECT',
  'GET_RANKING_LIST',
  'GET_RANKING_BY_USER',
  'GET_ROAD_MAP_ALL',
  'GET_LIST_QUESTION_BY_EXAMID',
  "SET_CODE_SELECT",
  "SET_LOADING",
  'GET_LIST_CURRENT_LESSON_USER',
  'GET_LIST_MOSHI_EXAM_RESULT_IMPORT',
  'GET_CURRENT_EXAM',
  'GENERATE_EXAM',
  'CREATE_FIRST_EXAM',
  'GET_MOSHI_SMALL_EXAM',
  'MOSHI_SCORING',
  'MOSHI_RESET',
  'UPDATE_STUDY_MODE',
  'CLEAR_MOSHI_SCORE',
  'UPDATE_STATUS_EXAM'
];
